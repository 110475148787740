import { useEffect, useState } from "react";
import axios from "axios";

import { BASE_URL } from "../../env/Baseurl";
import ErrorLogging from "../error-logs";
import RefreshToken from "../refresh-token";

const GetCasualThoughtResponse = () => {
  const [casualThoughtResponse, setCasualThoughtResponse] = useState([]);
  const [responseErrorMessage, setResponseErrorMessage] = useState("");

  const fetchCasualThoughtResponse = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const response = await axios.get(
        BASE_URL + `casual_thought_show_responce/${user.userInfo._id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            languageCode: user.userInfo.languageCode,
          },
        }
      );
      setCasualThoughtResponse(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        fetchCasualThoughtResponse();
      } else if (
        error.response.status === 403 ||
        error.response.status === 500 ||
        error.response.status === 503
      ) {
        setResponseErrorMessage(
          "Internal Server Error, Please Try After Sometime!"
        );
        ErrorLogging("HTTP GET /casual_thought_show_responce" + error);
      } else {
        setResponseErrorMessage("There is some error, please try again later.");
        ErrorLogging("HTTP GET /casual_thought_show_responce" + error);
      }
    }
  };

  useEffect(() => {
    fetchCasualThoughtResponse();
  }, []);

  return [casualThoughtResponse, responseErrorMessage];
};

export { GetCasualThoughtResponse };
