import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { DOMAIN_NAME } from '../../env/Baseurl';

// ============Local Storage ==========================

// const userInfo = localStorage.getItem('user'); const user = JSON.parse(userInfo);
// const languageCode =user.userInfo.languageCode;


/*******   Constants  *******/

const UserSituationInfo = ({ handleChange, selectChange, emptyCheck, values, setValues }) => {

    //  styling*******************************
    const style = {
        placeHolderStyle: {
            fontSize: '14px',
            color: '#929BA2'
        }
    }


    /*******   States  *******/
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOptionSecond, setSelectedOptionSecond] = useState(null);
    const {t}=useTranslation();

    const options = [
        { value: t('value1'), label: t('value1') },
        { value: t('value2'), label: t('value2') },
        { value: t('value3'), label: t('value3') },
        { value: t('value4'), label: t('value4') }
    ];
    
    const options2 = [
        { value: t('value5'), label: t('value5') },
        { value: t('value6'), label: t('value6') }
    ];

    // useEffect(() => {
    //     console.log("Its paricipatant value ", values.participant_options)
    // }, [participantOptionsValue])

    /*******   HelperFunctions  *******/
    const selectHandler = e => {
        setSelectedOption(e.value);
        if (e.value === "I am a person who stutters") {
            setValues({ ...values, ["user_situation"]: e.value, ["applied_situation"]: "" });
        } else {
            setValues({ ...values, ["user_situation"]: e.value, ["applied_situation"]: "none" });
        }
    };

    const selectHandler2 = e => {
        setSelectedOptionSecond(e.value);
        setValues({ ...values, ["applied_situation"]: e.value });
    };

    /*******   Render  *******/

    return (
        <div>
            <div className="row align-items-center login-col-wrap m-0">
                <div className="col-md-6">
                    <div className="text-center">
                        <img
                            height="auto"
                            width="450px"
                            src={`${DOMAIN_NAME}images/login/login8464.png`}
                            alt="Login Group"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <h3 className="text-center pagehead-texttitle">
                            {t('yourSituation')}
                        </h3>
                        <form>
                            <div className="card-body usersituation-body">
                                {/* <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">
                                        Research Participant
                                    </label>
                                    <Select
                                        placeholder="Choose an Option"
                                        defaultValue=
                                        {values.participant_options === "" ?
                                            participantOptionsValue
                                            :
                                            { label: values.participant_options, value: values.participant_options }

                                        }
                                        options={researchParticipantOptions}
                                        onChange={e => { selectHandlerParticipantOptions(e) }}
                                    />
                                    {values.participant_options === "" && (
                                        <small className="text-danger">
                                            {emptyCheck.participant_options}
                                        </small>
                                    )}
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">
                                    {t('mySituation')}
                                    </label>
                                    <Select
                                        // placeholder="Choose an Option"
                                        placeholder={<div style={style.placeHolderStyle}>{t('chooseoption')}</div>}
                                        defaultValue=
                                        {values.user_situation === "" ?
                                            selectedOption
                                            :
                                            { label: values.user_situation, value: values.user_situation }
                                        }
                                        options={options}
                                        onChange={e => { selectHandler(e) }}
                                    />
                                    {values.user_situation === "" && (
                                        <small className="text-danger">
                                            {emptyCheck.user_situation}
                                        </small>
                                    )}
                                </div>
                                {selectedOption === t('value2') || values.user_situation === t('value2') ?
                                    <div>
                                        <div className="form-group">
                                            <p>{t('stuttersQuestion')}</p>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">
                                            {t('stuttersQuestion2')}
                                            </label>
                                            <Select
                                                placeholder="Choose an Option"
                                                defaultValue=
                                                {values.applied_situation === "" ?
                                                    selectedOptionSecond
                                                    :
                                                    { label: values.applied_situation, value: values.applied_situation }
                                                }
                                                options={options2}
                                                onChange={e => { selectHandler2(e) }}
                                            />
                                            {values.applied_situation === "" && (
                                                <small className="text-danger">
                                                    {emptyCheck.applied_situation}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );

};

export default UserSituationInfo;