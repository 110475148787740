import { useState, useEffect, useTransition } from "react";
import { DOMAIN_NAME } from "../../env/Baseurl";
import Select from "react-select";
import { language_list } from "../../constants/languages/languages";
import { useTranslation } from "react-i18next";

const style = {
  placeHolderStyle: {
    fontSize: "14px",
    color: "#929BA2",
  },
};

const UserLanguage = ({ emptyCheck, values, setValues }) => {
  const { t } = useTranslation();
  // ============Local Storage ==========================

  // const userInfo = localStorage.getItem('user'); 
  // const user = JSON.parse(userInfo);
  // const languageCode =user.userInfo.languageCode;


  const [language, setLanguage] = useState("");

  const languageHandler = (e) => {
    setLanguage(e.value);
    setValues({ ...values, languageCode: e.value, language: e.label });
    // localStorage.setItem('languageCode', e.value);
    // localStorage.setItem('language', e.label);
  };

  // useEffect(() => {
  //   const savedLanguageCode = localStorage.getItem('languageCode');
  //   const savedLanguage = localStorage.getItem('language');
  //   if (savedLanguageCode && savedLanguage) {
  //     setLanguage(savedLanguageCode);
  //     setValues({ ...values, languageCode: savedLanguageCode, language: savedLanguage });
  //   }
  // }, [values]);

  return (
    <div>
      <div className="row align-items-center login-col-wrap m-0">
        <div className="col-md-6">
          <div className="text-center">
            <img
              height="auto"
              width="450px"
              src={`${DOMAIN_NAME}images/login/login8464.png`}
              alt="Login Group"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <h3 className="text-center pagehead-texttitle">{t('yourLang')}</h3>
            <form>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">
                    {t('yourLang')}
                  </label>
                  <Select
                    // placeholder="Choose Your Country"
                    placeholder={
                      <div style={style.placeHolderStyle}>
                        {t('chooseLanguage')}
                      </div>
                    }
                    defaultValue={
                      values?.language === ""
                        ? language
                        : {
                          label: values?.language,
                          value: values?.languageCode,
                        }
                    }
                    options={language_list}
                    onChange={languageHandler}
                  />
                  {values?.languageCode === "" && (
                    <small className="text-danger">
                      {emptyCheck?.languageCode}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLanguage;
