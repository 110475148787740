import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_NAME } from '../../env/Baseurl';
import PasswordStrengthMeter from '../global/PasswordStrengthMeter';

const UserPasswordInfo = ({ handleChange, emptyCheck, values }) => {
    const {t}=useTranslation();

    // ============Local Storage ==========================

    // const userInfo = localStorage.getItem('user'); 
    // const user = JSON.parse(userInfo);
    // const languageCode =user.userInfo.languageCode;


    /*******   States  *******/
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPassShown, setConfirmPassShown] = useState(false);

    /*******   HelperFunctions  *******/

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPassShown(confirmPassShown ? false : true);
    };

    /*******   Render  *******/

    return(
        <div>
            <div className="row m-0 align-items-center login-col-wrap">
                <div className="col-md-6">
                    <div className="text-center">
                        <img 
                            height="auto" 
                            width="450px" 
                            src={`${DOMAIN_NAME}images/login/login8464.png`} 
                            alt="Login Group"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <h3 className="text-center pagehead-texttitle">
                            {t('setPassword')}
                        </h3>
                        <form>
                            <div className="card-body">
                                <div className="form-group forminut-passeye">
                                    <div className="labelSection">
                                        <label htmlFor="exampleInputEmail1">
                                        {t('password')}
                                        </label>
                                        <div className="passStren">
                                        {t('passwordStrength')} : 
                                            <div>
                                                <PasswordStrengthMeter password={password} />
                                            </div>
                                        </div>
                                    </div>
                                    <input 
                                        type={passwordShown ? "text" : "password"} 
                                        className="form-control" 
                                        id="exampleInputEmail1"
                                        defaultValue={values.password}  
                                        onChange={ e => setPassword(e.target.value) } 
                                        onBlur={handleChange("password")}
                                    />
                                    <div className="passShow-eye" onClick={togglePasswordVisiblity}><i className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
                                            {values.password === "" && ( 
                                                <small className="text-danger">
                                                    {emptyCheck.password}
                                                </small>
                                            )}
                                </div>
                                <div className="form-group forminut-confirmPassShow">
                                    <div className="confirmpass-label-ins">
                                        <label htmlFor="exampleInputPassword1">
                                        {t('confirmPassword')}
                                        </label>
                                            {values.confirm_password === "" || values.password !== values.confirm_password || emptyCheck.confirm_password === "Password match."  ?
                                                <>
                                                <small className={emptyCheck.confirm_password === "Password match." ? "text-success password-match-instr" : "text-danger password-match-instr" }>
                                                    {emptyCheck.confirm_password}
                                                </small>
                                                </>:
                                                ""
                                            }
                                    </div>
                                    <input 
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                        }}
                                        type={confirmPassShown ? "text" : "password"}  
                                        className="form-control" 
                                        id="exampleInputPassword1"
                                        defaultValue={values.confirm_password}  
                                        onChange={ handleChange("confirm_password") } 
                                        onBlur={ e => setCPassword(e.target.value) } 
                                    />
                                    <div className="confirmPassShow-eye" onClick={toggleConfirmPasswordVisiblity}><i className={confirmPassShown ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </form>    
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserPasswordInfo;